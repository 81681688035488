import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"

import HowdyImage from "../images/howdy.png"

const divStyles = {
    maxWidth: "100%",
    padding: "0 2vw 0 2vw",
}

const imgStyles = {
    width: "100%",
}

const TemplatePage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Howdy</h1>
            <p>My name is Spencer Gautreaux, welcome to my e-portfolio. Links are at the top.</p>
            <p>Here is an image of some of my projects. You can read all about these and more on the <a href="/projects">Projects Page</a></p>
            <div style={divStyles}>
                <a href="/projects">
                    <img src={HowdyImage} style={imgStyles} alt="Projects I have worked on."/>
                </a>
            </div>
            <p>A little about me: I'm a computer science major by degree but in reality my skills are broad and I'm always learning. I can talk about memory fences and cache locality then turn around and CAD (Computer Aided Design) a robot arm. I can talk about designing a part to be injection molded for a children's doll house but then also tell you about the chaos of being a computer science TA (Teacher's Assistant). I can talk about the GPU programming that I've done for course projects and my (multiple) times developing new 3D printing methods and my experience actually machining and assembling the things I've designed. Its a bit of a shotgun blast of seemingly disparate skills, yet it all comes together to make something truly one of a kind. I hope you will take a moment to look at the project page, read through how I've applied all of the above, and more. I'm always seeking new opportunities and would be happy to discuss how I can apply my skills towards your company's mission.</p>
            {FooterBar()}
        </div>
    )
}

export default TemplatePage